

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

img {
  width: 400px;
  height: 200px;
 }


.App-link {
  color: #61dafb;
}



li {
  padding: 10px 0;
}

button {
  font-size: 30px;
  margin-right: 30px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  float: right;
}

/* Class */
.navBar {
  width: 100%;
  background-color: rgb(194, 189, 228);
  color: white;
  padding: 40px;
  align-items: center;
}

.links {
height: 0;
overflow: hidden;
list-style-type: none;
font-weight: bold;
}


.formfield * {
  vertical-align: middle;
}


.show-nav {
  height: 120px;
}

/* Media screen size */
@media screen and (min-width: 768px) {
  button {
      display: none;
  }

  .links {
      height: auto;
      display: inline;
  }

  li {
  /*  float: left;   */ 
    display: inline-block;
    
  }
  
  a {
    display: block;
    padding: 8px;
    background-color: #dddddd;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}
